* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  
  .logo img {
    margin-top: 12px;
    width: 60px;
    float: left;
  }
  
  .logo {
    float: left;
    margin-right: 1px;
  }
  
  .col-100 {
    border-bottom: 5px solid #c1f4c346;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .logo img {
      margin-top: 8px;
      width: 40px;
    }
  }
  

  .main,
  .content {
    margin: 0 auto;
    width: 980px;
    position: relative;
  }
  
  .header-1 {
    background-color: white;
    margin: 0 auto;
    padding: 16px;
    width: 980px;
  }
  
  .urls {
    border-bottom: 3px solid #f7f6f6;
    height: 60px;
    width: 100%;
  }
  
  .menu {
    width: 60%;
    height: 50px;
    float: left;
    margin-left: 5vh;
  }
  
  .menu ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .menu ul li {
    margin-left: 10px;
    height: 40px;
    display: inline-block;
    margin-left: 30px;
  }
  
  .menu li:hover {
    border-bottom: 3px solid #1a6859;
  }
  
  .menu a:hover {
    color: #1a6859;
    width: 30px;
  }
  
  .menu a {
    color: #15594D;
    text-decoration: none;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    font-weight: 500;
  }
  
  /* Responsiveness */
  @media (max-width: 980px) {
    .main,
    .content,
    .header-1 {
      width: 100%;
      max-width: 100%;
      padding: 10px;
    }
    
    .menu {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
    
    .menu ul {
      justify-content: center;
    }
    
    .menu ul li {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  

  .busca {
    text-align: center;
    width: 15%;
    margin-top: 14px;
    float: left;
    margin-left: 15vw;
  }
  
  .busca input {
    margin-top: 2px;
    color: #15594D;
    height: 20px;
    padding: 15px;
    width: 300px;
    background-color: #F8F8F8;
    border: none;
    border-radius: 18px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 11px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  }
  
  .busca input:hover {
    font-weight: bold;
    background-color: #04c28357;
  }
  
  .busca input:focus {
    outline: none;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
  
  .menu-url {
    width: 100%;
    position: relative;
  }
  
  .slider-container {
    position: relative;
    overflow: hidden;
  }
  
  .slider-principal {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    flex: 0 0 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .slide img {
    width: 100%;
    height: 480px;
    object-fit: cover;
    margin-bottom: -10px;
  }
  
  /* Responsiveness */
  @media (max-width: 980px) {
    .busca {
      width: 100%;
      margin: 10px auto;
      float: none;
      text-align: center;
    }
    
    .busca input {
      width: 100%;
      max-width: 300px;
    }
    
    .slider-principal {
      flex-wrap: wrap;
    }
    
    .slide {
      flex: 0 0 50%;
    }
    
    .slide img {
      height: auto;
    }
  }
  


  .video-texto {
    position: relative;
    padding: 0;
    overflow: hidden;
    max-width: 100%;
  }
  
  .video-container {
    width: 50%;
    float: right;
    position: relative;
  }
  
  .video-container video {
    width: 100%;
    height: auto;
    margin-bottom: -10px;
  }
  
  .texto-container {
    position: absolute;
    top: 40%;
    max-width: 45%;
    left: 20px;
    right: auto;
    text-align: justify !important;
    transform: translateY(-50%);
    color: #151817a2;
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    letter-spacing: 0.8px;
    font-weight: 500;
    text-align: left;
    margin: 15px;
    line-height: 1.5;
    z-index: 2;
  }
  
  .button-video {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .my-button {
    background-color: #11473dcd;
    color: #fff;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .my-button:hover {
    background-color: #037b65e6;
  }
  
  .serviços {
    width: 100%;
    height: 480px;
    max-height: 480px;
    background-color: #d0eaca76;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    line-height: 2.5;
  }
  
  .imagem-container p {
    font-size: 14px !important;
    color: grey;
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
    line-height: 1.4 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  
  .imagem-container h5 {
    color: #15594D;
  }
  
  .imagem-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .imagem-container img {
    width: 20vw;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 0 2px rgba(212, 212, 212, 0.215);
    margin-bottom: 10px;
  }
  
  .imagem-container p {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.2;
    text-align: justify;
  }
  
  /* Responsiveness */
  @media (max-width: 980px) {
    .video-container {
      width: 100%;
      float: none;
    }
    
    .texto-container {
      position: static;
      max-width: 100%;
      left: auto;
      right: auto;
      text-align: justify !important;
      transform: none;
      top: auto;
      margin: 15px;
    }
    
    .serviços {
      flex-wrap: wrap;
      height: auto;
      padding: 10px 0;
    }
    
    .imagem-container {
      width: 100%;
      max-width: 400px;
    }
    
    .imagem-container img {
      width: 100%;
      height: auto;
    }
  }
  
  .card-container {
    width: 100%;
    background-color: #d0eaca76;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    border-top: 5px solid #c1f4c346;
  }
  
  .card {
    display: flex;
    margin: 10px;
  }
  
  .box {
    background-color: rgba(255, 255, 255, 0.341);
    padding: 30px;
    padding-top: 30px;
    height: 380px;
    max-width: 480px;
    margin-top: 0px;
    text-align: center;
    box-shadow: 0px 1px 2px #65a6534d;
    border-radius: 18px;
    overflow: auto;
  }
  
  .box p {
    margin-top: 10px;
    font-size: 15px;
    line-height: 1.2;
    text-align: justify;
    overflow: hidden;
    word-wrap: break-word;
    max-height: 480px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: rgb(105, 105, 105);
  }
  
  .box h4 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #15594D;
  }
  
  .box h5 {
    color: #15594D;
    font-weight: normal;
  }
  
  .text-container {
    text-align: center;
    padding-bottom: 10px;
  }
  
  .text-container hr {
    border: none;
    border-top: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .box h4 {
    margin-top: 8px;
  }
  
  .depoimentos {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left: 30px;
    color: #11473d;
  }
  
  .logo-img {
    margin-left: 10px;
    width: 60px;
  }
  
  .serviçosbi {
    width: 100%;
    min-height: 450px;
    background-color: #31881e31;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
  }
  
  .imagem-bi {
    display: flex;
    align-items: center;
  }
  
  .imagem-bi img {
    height: 350px;
    object-fit: cover;
    border-radius: 18px;
    box-shadow: 0 0 0 2px rgba(230, 229, 229, 0.385);
    margin-right: 35px;
    overflow-wrap: break-word;
  }
  
  .imagem-bi p {
    font-size: 16px;
    line-height: 1.2;
    text-align: right;
  }
  
  .texto-bi h4 {
    text-align: center;
    margin-top: -15px;
    color: #15594D;
  }
  
  .texto-bi p {
    margin-top: 15px;
    font-size: 15px;
    line-height: 1.5;
    color: rgb(70, 70, 70);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  
  .bloco-clientes {
    width: 100%;
    padding: 10px;
    height: 60px;
    background-color: #ffffff36;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .col-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .col-4 img {
    cursor: pointer;
    margin: 0 auto;
    vertical-align: middle;
    width: 40%;
  }
  
  .col-4 .small-image {
    width: 20%;
  }
  
  .col-4 .abraceimg {
    width: 32%;
  }
  
  /* Responsividade */
  @media screen and (max-width: 768px) {
    .card-container {
      flex-wrap: wrap;
    }
    
    .box {
      width: 100%;
      max-width: none;
      height: auto;
      padding: 15px;
      margin-bottom: 20px;
    }
    
    .card {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .imagem-bi {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-right: 0;
      margin-bottom: 20px;
    }
    
    .texto-bi p {
      text-align: center;
    }
    
    .col-4 img {
      width: 60%;
    }
    
    .col-4 .small-image {
      width: 40%;
    }
    
    .col-4 .abraceimg {
      width: 50%;
    }
  }
  

.bloco-clientes h2 {
    margin: 0;
    font-size: 12px;
    /* Aumenta o tamanho do h2 para melhor legibilidade */
    color: #9f9f9fcc;
    white-space: nowrap;
    /* Evita que o texto do h2 quebre em várias linhas */
    overflow: visible;
    text-overflow: ellipsis;
    /* Adiciona reticências (...) ao final do texto que não couber no h2 */

}

.contato {
    width: 100%;
    height: 100%;
    background-color: #31881e31;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

}

.header {
    width: 100%;
    height: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

}

.header h1 {
    color: #207c6a;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 25px;
    margin-bottom: 10px;
}

.leftBox {
    width: 50%;
    height: 500px;
    display: block;
    overflow: hidden;
    margin: 5px auto;
    border-radius: 5px;
    background-color: #c7ddcd98;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.form-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 20px;
}

.form-row label {
    margin-top: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin-left: 20px;
    margin-bottom: 0;
}

.form-row input,
.form-row select {
    height: 35px;
    border: none;
    border-radius: 5px;
    margin-top: 2px;
    margin-left: 20px;
    background-color: #fafafa;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

}

input,
select:focus {
    outline: none;
}

.button-container {
    text-align: center;
}

.form-column input[name="msg"] {
    height: 80px;
    width: 200%;
    align-self: left;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

}

.btn {
    padding: 10px 25px;
    font-size: 14px;
    background-color: #11473dcd;
    color: #ffffff;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    margin-top: 20px;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.btn:hover {
    background-color: #037b65e6;
}

.contato-icones {
    width: 20px;
    height: 20px;
    margin: 2px;
    margin-right: 60px;
    display: inline-block !important;
    margin-top: 25px;
}

.rightBox {
    display: flex;
    margin: 5px auto;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.rightBox img:hover {
    position: relative !important;
    transform: scale(0.9);
}

.rightBox p:hover {
    position: relative !important;
    transform: scale(0.9);
    color: #31881e;
}

.icon-text , .link{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* Espaço entre as linhas de ícone + texto */
    font-size: 12px;
    color: #656565;
    margin-left: 5px;

}

.icon-text img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    /* Espaço entre o ícone e o texto */
    margin-left: 35px;
    margin-bottom: 20px;
}

.contato-text {
    margin-right: 30px;
}

.footer {
    background-color: #f2f2f2;
    padding: 15px 0;
    text-align: center;
}

.container-footer {
    max-width: 1200px;
    margin: 0 auto;
}

.container-footer p {
    margin: 0;
    font-size: 12px;
    color: #888888;
}